import { createApp, h } from "vue";
import { Link, createInertiaApp } from "@inertiajs/inertia-vue3";
import { InertiaProgress } from "@inertiajs/progress";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

const bugsnagApiKey = (document.querySelector('meta[name="bugsnag-api-key"]') as HTMLMetaElement).content;
if (bugsnagApiKey) {
  Bugsnag.start({
    apiKey: (document.querySelector('meta[name="bugsnag-api-key"]') as HTMLMetaElement).content,
    releaseStage: (document.querySelector('meta[name="bugsnag-release-stage"]') as HTMLMetaElement).content,
    appVersion: (document.querySelector('meta[name="bugsnag-app-version"]') as HTMLMetaElement).content,
    plugins: [new BugsnagPluginVue()],
  });
}

createInertiaApp({
  resolve: async (name) => {
    const pages = import.meta.glob("../Pages/**/*.vue");
    return (await pages[`../Pages/${name}.vue`]()).default;
  },
  setup({ el, app, props, plugin }) {
    const vueApp = createApp({ render: () => h(app, props) });
    vueApp.use(plugin);
    if (bugsnagApiKey) {
      const bugsnagVue = Bugsnag.getPlugin("vue");
      vueApp.use(bugsnagVue);
    }
    vueApp.component("InertiaLink", Link);
    vueApp.mount(el);
  },
});
InertiaProgress.init();
